<template>
  <b-card
    body-class="p-50"
    :class="`${isTakePhoto ? 'mb-0 border-info' : 'mb-50 mb-md-1 border-info-2'} `"
    :style="`${isTakePhoto ? 'box-shadow: 0px 4px 10px 0px #0000004D;' : ''}`"
  >
    <!-- SECTION - Header card -->
    <div class="d-flex-center flex-column flex-md-row justify-content-md-between mb-50">
      <div class="bg-blue-gradient w-100 mb-25 mb-md-0 d-flex">
        <div class="d-flex-center">
          <b-alert
            show
            variant="primary"
            class="p-75 p-md-1 mr-25 mr-md-75 mb-0"
            style="width: fit-content"
          >
            <feather-icon
              icon="DollarSignIcon"
              :size="isMobileView ? '20' : '30'"
              class="text-body"
            />
          </b-alert>
        </div>

        <div class="d-flex flex-column justify-content-center">
          <div :class="`text-airline fw-700 mb-0 mb-md-25 ${isMobileView ? 'font-medium-4' : 'font-medium-3'}`">
            {{ $t('flight.priceDetails') }}
            <feather-icon
              v-if="isMobileView"
              id="header-tooltip-mobile-price"
              icon="InfoIcon"
              size="16"
              class="text-info cursor-pointer"
            />
          </div>

          <b-tooltip
            v-if="isMobileView"
            target="header-tooltip-mobile-price"
            triggers="hover focus"
            boundary="window"
            variant="info"
          >
            <small> {{ $t('flight.priceDetail') }}</small>
          </b-tooltip>

          <span v-if="!isMobileView">
            {{ $t('flight.priceDetail') }}
          </span>
        </div>
      </div>

      <!-- ANCHOR SHOW HIDE OPTIONS -->
      <div
        v-if="!isTakePhoto"
        :class="isMobileView ? 'd-flex-center flex-wrap align-items-stretch' : 'd-flex flex-nowrap align-items-stretch'"
      >
        <b-button
          v-if="!isHideFunction"
          v-b-modal.modal-customer-service-fee
          size="sm"
          variant="outline-primary"
          :class="`rounded-lg text-nowrap mr-25 ${isMobileView ? 'px-75' : ''}`"
        >
          <span class="mr-25 text-nowrap align-middle">{{ $t('flight.setServiceFee') }}</span>
          <feather-icon
            v-if="!isMobileView"
            icon="EditIcon"
            size="12"
          />
        </b-button>
        <b-button
          v-if="!isHideFunction"
          v-b-tooltip.hover.v-warning.window
          variant="outline-warning"
          class="p-75 rounded-lg mx-25"
          :title="`${$t(showServiceFee ? 'hide' : 'show')} ${$t('flight.serviceCharge')}`"
          @click="showServiceFee = !showServiceFee"
        >
          <feather-icon
            icon="DollarSignIcon"
            size="16"
          />
        </b-button>
        <b-button
          v-if="!isHideFunction"
          v-b-tooltip.hover.v-info.window
          variant="outline-info"
          class="p-75 rounded-lg mx-25"
          :title="`${$t(showBenefit ? 'hide' : 'show')} ${$t('flight.profit')}`"
          @click="showBenefit = !showBenefit"
        >
          <feather-icon
            v-if="showBenefit"
            icon="EyeIcon"
            size="16"
          />
          <feather-icon
            v-else
            icon="EyeOffIcon"
            size="16"
          />
        </b-button>
        <!-- <b-button
          v-if="false"
          v-b-tooltip.hover.v-danger.window
          variant="flat-danger"
          class="p-75 rounded-circle mx-25"
          :title="`${showPromotion ? 'Ẩn' : 'Hiện'} chiết khấu vào giá`"
          @click="showPromotion = !showPromotion"
        >
          <feather-icon
            icon="PercentIcon"
            size="16"
          />
        </b-button> -->
      </div>
    </div>
    <!-- !SECTION -->

    <!-- SECTION - Modal Custom Service Fee -->
    <ModalCustomServiceFee />

    <!-- SECTION - Details Trip -->
    <Stack :class="`${isTakePhoto ? 'gap-2' : 'gap-3'}`">
      <b-card
        v-for="(trip, index) in selectedTrip"
        :key="index"
        class="border-secondary mb-0"
        header-class="py-50 justify-content-center"
        body-class="px-25 pb-25"
      >
        <template #header>
          <div :class="`fw-700 text-warning ${isMobileView ? 'font-medium-1' : 'font-medium-3'}`">
            {{ getAirportByAirportCode(trip.departure.IATACode) ? `${getAirportByAirportCode(trip.departure.IATACode).name} (${(trip.departure.IATACode)})` : trip.departure.IATACode }} <span class="px-50"> - </span> {{ getAirportByAirportCode(trip.arrival.IATACode) ? `${getAirportByAirportCode(trip.arrival.IATACode).name} (${trip.arrival.IATACode})` : trip.arrival.IATACode }}
          </div>
        </template>

        <div v-if="isTakePhoto && isMobileView">
          <BRow class="text-uppercase">
            <BCol
              v-for="column in tripPriceColumns.filter(item => item.key !== 'Service_Charge')"
              :key="column.key"
              cols="3"
              :class="`${column.key !== 'Passenger' ? 'text-right' : ''} text-nowrap`"
            >
              {{ column.label }}
            </BCol>
          </BRow>
          <BRow
            v-for="(priceItem, priceIndex) in resolvePriceData(trip)"
            :key="priceIndex"
            class="fw-700"
          >
            <!-- Passenger -->
            <BCol cols="3">
              <span :class="`text-dark align-middle text-nowrap mb-0 ${isMobileView && isTakePhoto ? 'text-right' : ''}`">
                {{ $t(`flight.${priceItem.paxType}`) }} x{{ priceItem.numPaxType }}
              </span>
            </BCol>
            <!-- Price -->
            <BCol cols="3">
              <div v-if="priceItem.discount || (showPromotion && priceItem.promotion)">
                <h6 class="text-secondary text-right text-nowrap">
                  <del class="text-danger">{{ formatCurrency(priceItem.fare) }}</del>
                </h6>
                <div class="text-dark text-right text-nowrap">
                  {{ showPromotion ? formatCurrency(priceItem.fare - priceItem.discount - priceItem.promotion) : formatCurrency(priceItem.fare - priceItem.discount) }}
                </div>
              </div>
              <div v-else-if="priceItem.paxType === 'Infant' && !priceItem.totalSurcharge && ((['AK'].includes(trip.source) && !draftBookingInfantPrice) || (['TH'].includes(trip.source) && !priceItem.fare))">
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                  small
                  tag="span"
                  class="text-right"
                />
              </div>
              <div
                v-else
                class="text-dark text-right text-nowrap"
              >
                {{ formatCurrency(priceItem.fare) }}
              </div>
            </BCol>
            <!-- Tax -->
            <BCol cols="3">
              <div v-if="!priceItem.totalSurcharge && priceItem.paxType === 'Infant' && ((['AK'].includes(trip.source) && !draftBookingInfantPrice) || (['TH'].includes(trip.source) && !priceItem.fare))">
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                  small
                  tag="span"
                  class="text-right"
                />
              </div>
              <div
                v-else
                class="text-dark text-right text-nowrap"
              >
                {{ formatCurrency(showServiceFee ? priceItem.totalSurcharge : (priceItem.totalSurcharge + priceItem.serviceFee)) }}
              </div>
            </BCol>

            <!-- Total -->
            <BCol cols="3">
              <div v-if="!priceItem.totalSurcharge && priceItem.paxType === 'Infant' && ((['AK'].includes(trip.source) && !draftBookingInfantPrice) || (['TH'].includes(trip.source) && !priceItem.fare))">
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                  small
                  tag="span"
                  class="text-right"
                />
              </div>
              <div
                v-else
                class="text-dark text-right text-nowrap"
              >
                <del
                  v-if="priceItem.discount || (showPromotion && priceItem.promotion)"
                  class="text-danger"
                > {{ showPromotion ? formatCurrency(priceItem.total + priceItem.discount + priceItem.promotion) : formatCurrency(priceItem.total + priceItem.discount) }}</del>
                <div class="text-dark">
                  {{ showPromotion ? formatCurrency(priceItem.total) : formatCurrency(priceItem.total + priceItem.promotion) }}
                </div>
              </div>
            </BCol>
          </BRow>
        </div>

        <b-table-lite
          v-else
          bordered
          responsive
          outlined
          hover
          small
          class="mb-1 rounded"
          thead-class="text-body text-center fw-800 text-nowrap"
          tbody-class="text-body text-center font-weight-bolder text-nowrap"
          :fields="showServiceFee ? tripPriceColumns : tripPriceColumns.filter(item => item.key !== 'Service_Charge')"
          :items="resolvePriceData(trip)"
        >
          <!-- <template
            v-for="(column) in tripPriceColumns"
            #[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="font-small-4"
            >
              {{ $t(`flight.${data.label}`) }}
            </span>
          </template> -->

          <template #cell(Passenger)="data">
            <h5 :class="`text-dark align-middle text-nowrap mb-0 ${isMobileView && isTakePhoto ? 'text-right' : ''}`">
              {{ $t(`flight.${data.item.paxType}`) }} x{{ data.item.numPaxType }}
            </h5>
          </template>

          <template #cell(Price)="data">
            <div v-if="data.item.discount || (showPromotion && data.item.promotion)">
              <h6 class="text-secondary text-right text-nowrap">
                <del class="text-danger">{{ formatCurrency(data.item.fare) }}</del>
              </h6>
              <div class="text-dark text-right text-nowrap">
                {{ showPromotion ? formatCurrency(data.item.fare - data.item.discount - data.item.promotion) : formatCurrency(data.item.fare - data.item.discount) }}
              </div>
            </div>
            <div v-else-if=" data.item.paxType === 'Infant' && !data.item.totalSurcharge && ((['AK'].includes(trip.source) && !draftBookingInfantPrice) || (['TH'].includes(trip.source) &&!data.item.fare))">
              <b-spinner
                variant="primary"
                label="Text Centered"
                small
                tag="span"
                class="text-right"
              />
            </div>
            <div
              v-else
              class="text-dark text-right text-nowrap"
            >
              {{ formatCurrency(data.item.fare) }}
            </div>
          </template>

          <template #cell(Tax)="data">
            <div v-if="!data.item.totalSurcharge && data.item.paxType === 'Infant' && ((['AK'].includes(trip.source) && !draftBookingInfantPrice) || (['TH'].includes(trip.source) && !data.item.fare))">
              <b-spinner
                variant="primary"
                label="Text Centered"
                small
                tag="span"
                class="text-right"
              />
            </div>
            <div
              v-else
              class="text-dark text-right text-nowrap"
            >
              {{ formatCurrency(showServiceFee ? data.item.totalSurcharge : (data.item.totalSurcharge + data.item.serviceFee)) }}
            </div>
          </template>

          <template #cell(Service_Charge)="data">
            <div v-if="!data.item.totalSurcharge && data.item.paxType === 'Infant' && ((['AK'].includes(trip.source) && !draftBookingInfantPrice) || (['TH'].includes(trip.source) && !data.item.fare))">
              <b-spinner
                variant="primary"
                label="Text Centered"
                small
                tag="span"
                class="text-right"
              />
            </div>
            <div
              v-else
              class="text-dark text-right text-nowrap"
            >
              {{ formatCurrency(data.item.serviceFee) || 0 }}
            </div>
          </template>

          <template #cell(Total)="data">
            <div v-if="!data.item.totalSurcharge && data.item.paxType === 'Infant' && ((['AK'].includes(trip.source) && !draftBookingInfantPrice) || (['TH'].includes(trip.source) && !data.item.fare))">
              <b-spinner
                variant="primary"
                label="Text Centered"
                small
                tag="span"
                class="text-right"
              />
            </div>
            <div
              v-else
              class="text-dark text-right text-nowrap"
            >
              <del
                v-if="data.item.discount || (showPromotion && data.item.promotion)"
                class="text-danger"
              > {{ showPromotion ? formatCurrency(data.item.total + data.item.discount + data.item.promotion) : formatCurrency(data.item.total + data.item.discount) }}</del>
              <div class="text-dark">
                {{ showPromotion ? formatCurrency(data.item.total) : formatCurrency(data.item.total + data.item.promotion) }}
              </div>
            </div>
          </template>
        </b-table-lite>

        <!--Trip price -->
        <div
          class="d-flex flex-wrap flex-md-nowrap"
          :class="isMobileView ? 'flex-column' : 'justify-content-between px-1'"
        >
          <!-- ANCHOR - SHOW Chiết khấu -->
          <div
            class="flex-grow-1"
            :class="isMobileView ? 'mx-1' : 'mr-2'"
          >
            <div v-show="showBenefit">
              <div
                class="d-flex-between"
                style="min-width: 250px"
              >
                <strong>{{ $t('flight.promotion') }}:</strong>
                <strong class="font-medium-1">
                  {{ formatCurrency(getTotalPriceTrip(trip, dataSearchFlight).promotions) }}
                </strong>
              </div>
              <div
                class="d-flex-between"
                style="min-width: 250px"
              >
                <div>
                  <strong>{{ $t('flight.profit') }}:</strong>
                  <div>
                    <em>
                      <small>
                        (<span v-if="!showPromotion">{{ $t('flight.promotion') }} + </span>{{ $t('flight.serviceCharge') }}<span v-if="!trip.fareOptions.hideFee"> - {{ $t('flight.agencyFee') }}</span>)
                      </small>
                    </em>
                  </div>
                </div>
                <strong class="font-medium-1">
                  {{ formatCurrency(getTotalPriceTrip(trip, dataSearchFlight).benefit) }}
                </strong>
              </div>
            </div>
          </div>

          <!-- ANCHOR - Tổng -->
          <div :class="`flex-grow-1 ${isMobileView ? '' : 'ml-2'}`">
            <div class="d-flex flex-column justify-content-end align-items-end fw-700">
              <div class="d-flex flex-column flex-md-row mr-1 mr-md-0 align-items-center">
                <div class="text-nowrap mr-1">
                  <span class="mr-1 font-medium-2 text-danger">{{ $t('flight.total') }} : </span>
                  <span class="font-medium-2 text-danger">{{ formatCurrency(getTotalPriceTrip(trip, dataSearchFlight).total) }}</span>
                </div>
                <div
                  v-if="showBenefit"
                  class="mr-1 mr-md-0 text-warning"
                >
                  <!-- <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span> {{ $t('flight.noteDiscount') }}</span> -->

                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span> {{ $t('flight.noteDiscount') }}</span>
                </div>

                <!-- <div class="mr-1 mr-md-0">
                  <feather-icon
                    class="text-warning mr-25"
                    icon="InfoIcon"
                  />
                  <span class="text-nowrap text-italic text-warning font-small-3">{{ $t('flight.fareText') }}</span>
                </div> -->
              </div>

              <!-- <div
                v-if="showBenefit"
                class="text-info font-small-3 mr-1 mr-md-0"
              >
                <div class="text-warning">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span> {{ $t('flight.noteDiscount') }}</span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </b-card>
    </Stack>
    <!-- !SECTION -->

    <!-- SECTION - Chi tiết hành lý, dịch vụ thêm -->
    <b-card
      v-if="!isEmpty(addonDetails) && !isTakePhoto"
      class="border-secondary mt-1"
      body-class="px-0 py-75"
    >
      <div class="font-medium-3 fw-700 mb-50 px-1">
        {{ $t('flight.Service') }}
      </div>

      <b-table-lite
        bordered
        responsive
        small
        hover
        class="mb-1 rounded"
        thead-class="text-body text-nowrap fw-800"
        tbody-class="text-body font-weight-bolder"
        :items="addonDetails"
        :fields="addonsPriceColumns"
      >
        <template
          v-for="(column) in addonsPriceColumns"
          #[`head(${column.key})`]="data"
        >
          <div
            :key="column.label"
            class="text-body"
          >
            <span>
              {{ $t(`flight.${data.label}`) }}
            </span>
          </div>
        </template>

        <template #cell(Passenger)="{item}">
          <span
            class="text-nowrap"
            :class="isMobileView ? 'font-small-3' : 'font-medium-1'"
          >
            {{ (item.firstName && item.lastName)
              ? `${item.lastName} ${item.firstName}`
              : `${item.paxType} #${item.paxId}` }}
          </span>
        </template>

        <template #cell(flight_segment)="{item}">
          {{ item.flights }}
        </template>

        <template #cell(Airline)="{item}">
          <div class="text-center">
            {{ item.airline ? getAirlineNameByCode(item.airline) : item.airline }}
          </div>
        </template>

        <template #cell(Service)="{item}">
          <div style="min-width: 170px;">
            {{ resolveSsrNameByCode(item.serviceName) }}
          </div>
        </template>

        <template #cell(Price)="{item}">
          {{ formatCurrency(item.addonPrice) }}
        </template>

        <template #cell(Amount)="data">
          <div class="text-body">
            <div class="d-flex justify-content-center align-items-center">
              <!-- <b-button
                v-if="data.item.ssrName === 'ssrBags'"
                :id="`btn-decrease-${data.item.ssrId}`"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle d-none"
                :disabled="data.item.amount === 1"
                @click="$emit('handle-service', 'decrease', data.item)"
              >
                <feather-icon
                  class="font-weight-bolder"
                  icon="MinusIcon"
                />
              </b-button> -->
              <span
                style="min-width:25px"
                class="d-inline-block"
              >
                {{ data.item.amount }}
              </span>
              <!-- <b-button
                v-if="data.item.ssrName === 'ssrBags'"
                :id="`btn-increase-${data.item.ssrId}`"
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                variant="flat-info"
                class="btn-icon rounded-circle font-weight-bolder d-none"
                @click="$emit('handle-service', 'increase', data.item)"
              >
                <feather-icon
                  icon="PlusIcon"
                />
              </b-button> -->
            </div>
          </div>
        </template>

        <template #cell(Action)="data">
          <div class="text-center">
            <b-button
              v-if="!isHideFunction"
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="danger"
              class="btn-icon rounded p-50"
              @click="$emit('handle-delete-service', data.item)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table-lite>

      <!-- ANCHOR - Total Price Addon -->
      <b-row class="d-flex justify-content-between align-items-end fw-700">
        <b-col v-if="isWarningWeightBaggageAK">
          <b-alert
            show
            variant="danger"
            class="my-0 fw-700 mx-1 p-50 text-center"
            style="min-width: 150px"
          >
            Trọng lượng tối đa của hành lý là 32kg mỗi kiện
          </b-alert>
        </b-col>

        <b-col class="d-flex align-items-center justify-content-end py-50">
          <div class="d-flex flex-nowrap mr-1">
            <span class="mr-1 font-medium-2 text-nowrap text-danger">{{ $t('flight.total') }} : <span class="font-medium-2 text-danger">{{ formatCurrency(totalPriceAddon) }}</span> </span>

            <div class="text-nowrap mr-md-0">
              <feather-icon
                class="text-warning mr-25"
                icon="InfoIcon"
              />
              <span class="text-nowrap text-italic text-warning font-small-3">{{ $t('flight.fareText') }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- SECTION - Chi tiết chỗ ngồi -->
    <b-card
      v-if="!isTakePhoto"
      :class="`border-secondary mt-1 ${isEmpty(selectedSeatsDetail) ? 'd-none' : ''}`"
      body-class="px-0 py-75"
    >
      <div class="font-medium-3 fw-700 mb-50 px-1">
        <!-- {{ $t('flight.seatDetails') }} -->
        Chỗ ngồi
      </div>

      <b-table-lite
        bordered
        responsive
        small
        hover
        class="mb-1 rounded"
        thead-class="text-body text-nowrap fw-800"
        tbody-class="text-body text-nowrap font-weight-bolder"
        :items="selectedSeatsDetail"
        :fields="seatsPriceColumns"
      >
        <template
          v-for="column in seatsPriceColumns"
          #[`head(${column.key})`]="data"
        >
          <span :key="column.label">
            {{ $t(`flight.${data.label}`) }}
          </span>
        </template>

        <template #cell(Passenger)="data">
          {{ (data.item.passenger.firstName && data.item.passenger.lastName)
            ? `${data.item.passenger.lastName} ${data.item.passenger.firstName}`
            : `${data.item.passenger.paxType} #${data.item.passenger.paxId}` }}
        </template>

        <template #cell(flight_segment)="{item}">
          {{ item.segment.segmentFlights }}
        </template>

        <template #cell(Airline)="{item}">
          {{ getAirlineNameByCode(item.segment.airline) }}
        </template>

        <template #cell(Seat)="{item}">
          {{ item.seat.seat.seatName }}
        </template>

        <template #cell(Price)="{item}">
          {{ !isEmpty(item.seat.seat.fares) && item.seat.seat.fares[0].paxType === 'ALL'
            ? formatCurrency(item.seat.seat.fares[0].total)
            : !isEmpty(item.seat.seat.fares) && item.seat.seat.fares.find(f => f.paxType === item.passenger.paxType)
              ? formatCurrency(item.seat.seat.fares.find(f => f.paxType === item.passenger.paxType).total)
              : 0
          }}
        </template>

        <template #cell(Action)="{item}">
          <div class="text-center">
            <b-button
              v-if="!isHideFunction"
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="danger"
              class="btn-icon rounded p-50"
              @click="handleDeleteSeat(item)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table-lite>

      <!--ANCHOR - Tổng dịch vụ -->
      <div class="d-flex flex-column justify-content-end align-items-end fw-700 mr-1">
        <div class="d-flex flex-column flex-md-row mr-1 mr-md-0 align-items-center">
          <div class="text-nowrap mr-1">
            <span class="mr-1 font-medium-2 text-danger">{{ $t('flight.total') }} : </span>
            <span class="font-medium-2 text-danger">{{ formatCurrency(totalPriceSeatPrice) }}</span>
          </div>
          <div class="mr-1 mr-md-0">
            <feather-icon
              class="text-warning mr-25"
              icon="InfoIcon"
            />
            <span class="text-nowrap text-italic text-warning font-small-3">{{ $t('flight.fareText') }}</span>
          </div>
        </div>
      </div>
    </b-card>

    <!-- ANCHOR - Tổng dịch vụ trên modal chụp ảnh hành trình -->
    <div
      v-if="isTakePhoto && (totalPriceAddon + totalPriceSeatPrice) > 0"
      class="text-right fw-700 mr-md-1"
    >
      <span :class="`${isMobileView ? 'font-small-4' : 'font-medium-2'}`"> {{ $t('flight.Service') }}: </span>
      <span class="text-warning font-medium-5">
        {{ formatCurrency(totalPriceAddon + totalPriceSeatPrice) }}
      </span>
    </div>

    <!-- SECTION - Tổng chi phí -->
    <div class="text-right fw-700 mr-md-1">
      <span :class="`${isMobileView ? 'font-small-4' : 'font-medium-2'}`"> {{ $t('flight.totalFare') }}: </span>
      <span class="text-warning font-medium-5">
        {{ formatCurrency(getTotalPriceTicket(selectedTrip, dataSearchFlight) + totalPriceAddon + totalPriceSeatPrice) }}
      </span>
      <span class="text-nowrap text-italic font-small-3">({{ $t('flight.fareText') }})</span>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BAlert, BButton, BTableLite, VBTooltip, BSpinner, BTooltip, BRow, BCol,
} from 'bootstrap-vue'
import {
  computed, ref, watch, toRefs,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import Ripple from 'vue-ripple-directive'

import store from '@/store'
import { resolveSsrNameByCode } from '@/constants/selectOptions'
import { SOURCE_AK_ECONOMY_FARE } from '@/constants/flight'
import { env } from '@/libs/env'

import { formatCurrency } from '@core/utils/filter'

import useBookingHandle from '../useBookingHandle'
import useCreateBookingHandle from './useCreateBookingHandle'

export default {
  components: {
    BAlert,
    BCard,
    BButton,
    BTableLite,
    BSpinner,
    BTooltip,
    BRow,
    BCol,
    ModalCustomServiceFee: () => import('../result/components/ModalCustomServiceFee.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    selectedTrip: {
      type: Array,
      default: () => [],
    },
    dataSearchFlight: {
      type: Object,
      default: () => {},
    },
    paxSsrData: {
      type: Array,
      default: () => [],
    },
    passengerDataToAdd: {
      type: Array,
      default: () => [],
    },
    isTakePhoto: {
      type: Boolean,
      default: false,
    },
    draftBookingInfantPrice: {
      type: [Object, undefined],
      default: undefined,
    },
  },
  setup(props, { root }) {
    const {
      FLIGHT_APP_STORE_MODULE_NAME,
      getSelectedSeatsDetails,
      getAddonsData,
      getAirportByAirportCode,
    } = useBookingHandle()

    const { isEnableFnSourceAKJSON } = env
    const { draftBookingInfantPrice } = toRefs(props)

    const {
      isHideFunction,
    } = useCreateBookingHandle()

    const addonDetails = ref([])

    const isWarningWeightBaggageAK = computed(() => {
      if (isEmpty(addonDetails.value)) return false
      const isAK = source => ['AK'].includes(source)
      const isSsrBags = name => ['ssrBags'].includes(name)
      const isItineraryKULDMK = flights => ['KUL-DMK'].includes(flights)
      const isEconomyFare = segmentId => { // AK trar ra segmentId cua chang
        if (isEmpty(segmentId)) return false
        const segmentData = props.selectedTrip.find(trip => trip.segments.some(segment => segmentId === segment.segmentId))
        if (!segmentData) return false
        return SOURCE_AK_ECONOMY_FARE.includes(segmentData?.fareOptions?.bookingClass)
      }
      return addonDetails.value?.some(item => isAK(item?.source)
      && isSsrBags(item?.ssrName)
      && isItineraryKULDMK(item?.flights)
      && isEconomyFare(item?.segmentId)
      && (item.details?.[0]?.weight > 30))
    })

    const totalPriceSeatPrice = ref(0)
    const showBenefit = ref(false)
    const showPromotion = ref(false)
    const showServiceFee = ref(false)
    // dùng $t cho label để apply cho `stacked` của b-table
    const tripPriceColumns = [
      { label: root.$t('flight.passenger'), key: 'Passenger' },
      { label: root.$t('flight.Price'), key: 'Price' },
      { label: root.$t('flight.Tax'), key: 'Tax' },
      { label: root.$t('flight.serviceCharge'), key: 'Service_Charge' },
      { label: root.$t('flight.Total'), key: 'Total' },
    ]

    const addonsPriceColumns = [
      { label: 'passenger', key: 'Passenger' },
      { label: 'flightSegment', key: 'flight_segment' },
      { label: 'airline', key: 'Airline' },
      { label: 'Service', key: 'Service' },
      { label: 'Price', key: 'Price' },
      { label: 'Amount', key: 'Amount' },
      // { label: 'Total', key: 'Total' },
      { label: 'Action', key: 'Action' },
    ]

    const seatsPriceColumns = [
      { label: 'passenger', key: 'Passenger' },
      { label: 'flightSegment', key: 'flight_segment' },
      { label: 'airline', key: 'Airline' },
      { label: 'Seat', key: 'Seat' },
      { label: 'Price', key: 'Price' },
      { label: 'Action', key: 'Action' },
    ]

    const selectedSeatsDetail = computed(() => {
      if (getSelectedSeatsDetails.value) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        totalPriceSeatPrice.value = getSelectedSeatsDetails.value.reduce((total, seat) => total + Number(seat.seat.seat.fares[0].total), 0)
        return getSelectedSeatsDetails.value
      }
      return []
    })

    const totalPriceAddon = computed(() => {
      let total = 0
      if (addonDetails.value) {
        total = addonDetails.value.reduce((acc, item) => acc + (item.amount * item.addonPrice), 0)
      }
      return total
    })

    const handleDeleteSeat = ({ seat, segment }) => {
      const dataSeatSelect = {
        segmentFlights: segment.segmentFlights,
        itineraryId: segment?.airlineItineraryId,
        source: segment.source,
        seat: seat.seat,
      }
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/handleSeatSelect`, dataSeatSelect)
    }

    const customFeeServiceDataToModify = computed(() => store.getters['app-flight-v2/getCustomFeeServiceDataToModify'])
    const ssrOtherList = computed(() => store.getters['app-create-booking/getSsrOthers'] || [])

    function getPriceInfant(trip) { // nếu là AK JSON thì sẽ lấy giá ở draftBooking
      if (isEnableFnSourceAKJSON && draftBookingInfantPrice.value) {
        const result = {
          fee: draftBookingInfantPrice.value.net * trip.legs,
          tax: draftBookingInfantPrice.value.tax * trip.legs,
          total: draftBookingInfantPrice.value.total * trip.legs,
        }
        return result
      }

      const result = trip.segments.reduce((acc, item) => {
        const ssrInfant = ssrOtherList.value.find(ssr => ['INFT'].includes(ssr.code) && (item.source === 'TH' ? ((`${item.departure.IATACode}-${item.arrival.IATACode}`) === ssr.flights) : ssr.segmentIds.includes(item.segmentId)))
        if (ssrInfant) {
          acc.fee += ssrInfant.fares[0].fee
          acc.tax += ssrInfant.fares[0].tax
          acc.total += ssrInfant.fares[0].total
        }
        return acc
      }, { fee: 0, tax: 0, total: 0 })
      return result
    }

    const getTotalPriceTrip = (trip, passengers) => {
      const fare = trip?.fareOptions
      const { source, domestic } = trip
      if (!fare || !passengers) {
        return { total: 0, benefit: 0 }
      }
      const { hideFee, totalAgencyFee } = fare
      const ancillaryInfant = getPriceInfant(trip)

      const getFeeServiceInSelectedProfile = computed(() => store.getters['app-flight-v2/getFeeServiceInSelectedProfile'](domestic, source))
      const modifiedServiceFee = {
        adultAmount: null,
        childAmount: null,
        infantAmount: null,
      }
      // Nếu có custom pdv
      if (customFeeServiceDataToModify.value?.adultAmount || customFeeServiceDataToModify.value?.childAmount || customFeeServiceDataToModify.value?.infantAmount) {
        modifiedServiceFee.adultAmount = customFeeServiceDataToModify.value?.adultAmount * fare.amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = customFeeServiceDataToModify.value?.childAmount * fare.amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = customFeeServiceDataToModify.value?.infantAmount * fare.amountSegmentToMultipleFeeService
      } else if (getFeeServiceInSelectedProfile.value) {
      // nếu chọn profile pdv
        const isSegmentFeeType = getFeeServiceInSelectedProfile.value?.feeType === 'FLIGHT_SEGMENT'
        const amountSegmentToMultipleFeeService = isSegmentFeeType ? trip?.segments?.length : 1
        modifiedServiceFee.adultAmount = getFeeServiceInSelectedProfile.value?.adultAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = getFeeServiceInSelectedProfile.value?.childAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = getFeeServiceInSelectedProfile.value?.infantAmount * amountSegmentToMultipleFeeService
      }

      const serviceFeeAdultAfterCustom = modifiedServiceFee.adultAmount !== null ? modifiedServiceFee.adultAmount : fare.serviceFeeAdult
      const serviceFeeChildAfterCustom = modifiedServiceFee.childAmount !== null ? modifiedServiceFee.childAmount : fare.serviceFeeChild
      const serviceFeeInfantAfterCustom = modifiedServiceFee.infantAmount !== null ? modifiedServiceFee.infantAmount : fare.serviceFeeInfant

      const adultPrice = (fare.totalAdultModified + serviceFeeAdultAfterCustom - fare.serviceFeeAdult) * passengers.adult
      const childPrice = (fare.totalChildModified + serviceFeeChildAfterCustom - fare.serviceFeeChild) * passengers.child
      let infantPrice = (fare.totalInfantModified + serviceFeeInfantAfterCustom - fare.serviceFeeInfant) * passengers.infant

      // ANCHOR Tính tổng tiền nếu tính giá INFANT AK TH
      const infantPriceRaw = isEnableFnSourceAKJSON ? 0 : fare.totalInfant
      if (['AK', 'TH'].includes(trip.source) && ancillaryInfant && !infantPriceRaw) {
        const infPrice = isEnableFnSourceAKJSON ? (serviceFeeInfantAfterCustom * passengers.infant) : infantPrice
        infantPrice = ancillaryInfant.total * passengers.infant + infPrice
      }

      const totalPromotions = fare.promotionAdult * passengers.adult + fare.promotionChild * passengers.child + fare.promotionInfant * passengers.infant
      const benefit = (showPromotion.value
        ? (serviceFeeAdultAfterCustom * passengers.adult
          + serviceFeeChildAfterCustom * passengers.child
          + serviceFeeInfantAfterCustom * passengers.infant)
        : ((fare.promotionAdult + serviceFeeAdultAfterCustom) * passengers.adult
          + (fare.promotionChild + serviceFeeChildAfterCustom) * passengers.child
          + (fare.promotionInfant + serviceFeeInfantAfterCustom) * passengers.infant)) - (!hideFee ? totalAgencyFee : 0)

      const total = adultPrice + childPrice + infantPrice + (showPromotion.value ? 0 : totalPromotions)
      return { total, benefit, promotions: totalPromotions }
    }

    const getTotalPriceTicket = (trips, passengers) => {
      if (!trips || !passengers) {
        return 0
      }

      let totalPrice = 0
      trips.forEach(trip => {
        totalPrice += getTotalPriceTrip(trip, passengers).total
      })

      return totalPrice
    }

    const resolvePriceData = trip => {
      const paxTypes = ['Adult', 'Child', 'Infant']
      const { fareOptions, source, domestic } = trip
      const getFeeServiceInSelectedProfile = computed(() => store.getters['app-flight-v2/getFeeServiceInSelectedProfile'](domestic, source))
      const modifiedServiceFee = {
        adultAmount: null,
        childAmount: null,
        infantAmount: null,
      }

      const ancillaryInfant = getPriceInfant(trip)

      // Nếu có custom pdv
      if (customFeeServiceDataToModify.value?.adultAmount || customFeeServiceDataToModify.value?.childAmount || customFeeServiceDataToModify.value?.infantAmount) {
        modifiedServiceFee.adultAmount = customFeeServiceDataToModify.value?.adultAmount * fareOptions.amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = customFeeServiceDataToModify.value?.childAmount * fareOptions.amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = customFeeServiceDataToModify.value?.infantAmount * fareOptions.amountSegmentToMultipleFeeService
      } else if (getFeeServiceInSelectedProfile.value) {
      // nếu chọn profile pdv
        const isSegmentFeeType = getFeeServiceInSelectedProfile.value?.feeType === 'FLIGHT_SEGMENT'
        const amountSegmentToMultipleFeeService = isSegmentFeeType ? trip?.segments?.length : 1
        modifiedServiceFee.adultAmount = getFeeServiceInSelectedProfile.value?.adultAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = getFeeServiceInSelectedProfile.value?.childAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = getFeeServiceInSelectedProfile.value?.infantAmount * amountSegmentToMultipleFeeService
      }

      const {
        hideFee, agencyFee, hideFeeParent, agencyFeeParent,
      } = fareOptions
      return paxTypes.map(paxType => {
        const numPaxType = props.dataSearchFlight[paxType.toLowerCase()]
        // NOTE Code hiển thị giá đã nhân với numPaxType
        // const fare = fareOptions[`fare${paxType}`] * numPaxType
        // const totalSurcharge = (fareOptions[`surcharge${paxType}`] + fareOptions[`tax${paxType}`] + (paxType !== 'Infant' && hideFee ? agencyFee : 0)) * numPaxType
        // const discount = fareOptions[`discount${paxType}`] * numPaxType
        // const total = modifiedServiceFee[`${paxType.toLowerCase()}Amount`] !== null ? (modifiedServiceFee[`${paxType.toLowerCase()}Amount`] - fareOptions[`serviceFee${paxType}`] + fareOptions[`total${paxType}Modified`]) * numPaxType : fareOptions[`total${paxType}Modified`] * numPaxType
        // const promotion = fareOptions[`promotion${paxType}`] * numPaxType
        // const serviceFee = modifiedServiceFee[`${paxType.toLowerCase()}Amount`] !== null ? modifiedServiceFee[`${paxType.toLowerCase()}Amount`] * numPaxType : fareOptions[`serviceFee${paxType}`] * numPaxType

        // NOTE: Code giá từng pax
        let fare = fareOptions[`fare${paxType}`]
        let totalSurcharge = (fareOptions[`surcharge${paxType}`] + fareOptions[`tax${paxType}`] + (paxType !== 'Infant' && hideFee ? agencyFee : 0) + (paxType !== 'Infant' && hideFeeParent ? agencyFeeParent : 0))
        const discount = fareOptions[`discount${paxType}`]
        let total = modifiedServiceFee[`${paxType.toLowerCase()}Amount`] !== null ? (modifiedServiceFee[`${paxType.toLowerCase()}Amount`] - fareOptions[`serviceFee${paxType}`] + fareOptions[`total${paxType}Modified`]) : fareOptions[`total${paxType}Modified`]
        const promotion = fareOptions[`promotion${paxType}`]
        const serviceFee = modifiedServiceFee[`${paxType.toLowerCase()}Amount`] !== null ? modifiedServiceFee[`${paxType.toLowerCase()}Amount`] : fareOptions[`serviceFee${paxType}`]

        // ANCHOR Tính giá INFANT AK TH
        const totalSurchargeRaw = fareOptions[`surcharge${paxType}`] + fareOptions[`tax${paxType}`] // thuế phí sân bay ko bao gồm phí dịch vụ / xuất vé
        if (['AK'].includes(trip.source) && paxType === 'Infant' && ancillaryInfant && !(totalSurchargeRaw)) {
          fare = isEnableFnSourceAKJSON ? 0 : fare
          totalSurcharge += (ancillaryInfant.fee + ancillaryInfant.tax)
          total = isEnableFnSourceAKJSON
            ? ((modifiedServiceFee[`${paxType.toLowerCase()}Amount`] !== null) ? ((modifiedServiceFee[`${paxType.toLowerCase()}Amount`] - fareOptions[`serviceFee${paxType}`]) + ancillaryInfant.total) : (ancillaryInfant.total + fareOptions[`serviceFee${paxType}`]))
            : (total + ancillaryInfant.total)
        }

        if (['TH'].includes(trip.source) && paxType === 'Infant' && ancillaryInfant && !fare) {
          fare += (ancillaryInfant.fee + ancillaryInfant.tax)
          total += ancillaryInfant.total
        }

        const result = {
          paxType,
          fare,
          totalSurcharge,
          discount,
          total,
          numPaxType,
          promotion,
          serviceFee,
        }
        return result
      })
        .filter(item => item.numPaxType)
    }

    watch(() => getAddonsData.value, val => {
      addonDetails.value = val
    }, { deep: true, immediate: true })

    function getAirlineNameByCode(code) {
      return store.getters['globalConfig/getAirlineNameByCode'](code)
    }

    return {
      tripPriceColumns,
      addonsPriceColumns,
      getAirlineNameByCode,
      getAirportByAirportCode,
      resolveSsrNameByCode,

      formatCurrency,
      resolvePriceData,
      getTotalPriceTrip,
      getTotalPriceTicket,

      addonDetails,
      totalPriceAddon,
      isWarningWeightBaggageAK,

      seatsPriceColumns,
      selectedSeatsDetail,
      totalPriceSeatPrice,
      handleDeleteSeat,

      isEmpty,
      showBenefit,
      showServiceFee,
      showPromotion,
      isHideFunction,
    }
  },
}
</script>

<style scoped>
</style>
