<template>
  <BCard
    header-class="py-50 text-airline px-1"
    body-class="pb-0"
  >
    <template #header>
      <div :class="`fw-700 text-nowrap ${isMobileView ? 'font-medium-1' : 'font-medium-2'}`">
        {{ $t(`reservation.customerArchivedInfo`) }}
      </div>
      <BButton
        variant="flat-dark"
        class="p-50 text-nowrap"
        @click="$emit('toggleCollapse')"
      >
        <feather-icon
          v-if="!isCollapsed"
          icon="ChevronDownIcon"
          size="17"
        />
        <feather-icon
          v-else
          icon="ChevronUpIcon"
          size="17"
        />
      </BButton>
    </template>

    <BCollapse :visible="!isCollapsed">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            :name="$t('flight.fullName')"
          >
            <b-form-group label-for="fullName">
              <template #label>
                <div class="">
                  {{ $t('flight.fullName') }}
                </div>
              </template>

              <vue-autosuggest
                id="fullName"
                :value="`${customerToSelect?.lastName}${customerToSelect?.lastName && customerToSelect?.firstName ? ` ` : ''}${customerToSelect?.firstName}`"
                :suggestions="customerSuggestion"
                :input-props="{
                  id:'fullName',
                  placeholder: $t('flight.placeholderInput'),
                  state: `${getValidationState(validationContext) === false ? false : null}`,
                  class:`form-control ${getValidationState(validationContext) === false ? 'is-invalid' : ''} text-uppercase`,
                  disabled: !!customerToSelect.paxId,
                }"
                :section-configs="sectionConfigs"
                :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion,'fullName')"
                @input="val => inputHandle('fullName', val)"
              >
                <template slot-scope="{suggestion}">
                  <div class="cursor-pointer">
                    <b-row>
                      <b-col cols="12">
                        <HStack>
                          <b>{{ suggestion.item.lastName }} {{ suggestion.item.firstName }}</b>
                          <span v-if="suggestion.item?.nickName">({{ suggestion.item.nickName }})</span>
                        </HStack>
                      </b-col>

                      <b-col
                        cols="12"
                        class="text-nowrap"
                      >
                        {{ suggestion.item.phoneNumber }}
                      </b-col>
                      <b-col
                        cols="12"
                        class="text-nowrap"
                      >
                        {{ suggestion.item.emailAddress }}
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </vue-autosuggest>
              <b-tooltip
                v-if="validationContext.errors[0]"
                target="fullName"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
                :title="validationContext.errors[0]"
              >
                <span class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </span>
              </b-tooltip>
            </b-form-group>
          </validation-provider>
        </b-col>
        <HStack center>
          <div
            v-if="customerToSelect?.nickName"
            class="d-flex-center"
          >
            ({{ customerToSelect.nickName }})
          </div>
          <div
            v-if="customerToSelect.paxId"
            class="d-flex-center"
          >
            <BButton
              v-b-tooltip.hover.v-danger
              variant="flat-danger"
              class="p-50 text-nowrap"
              :title="$t('flight.removeCustomerSelected')"
              @click="removeCustomerSelected"
            >
              <feather-icon icon="XIcon" />
            </BButton>
          </div>
        </HStack>
      </b-row>

      <b-row class="mb-50">
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Phone"
            :rules="`${isReplacePhoneTemp ? 'required|' : ''}integer|phoneNumber`"
          >
            <!-- mode="eager" -->
            <b-form-group label-for="phone-contact">
              <template #label>
                <div class="">
                  {{ $t('flight.phoneNumber') }}
                  <span
                    v-if="isReplacePhoneTemp"
                    class="text-danger"
                  >(*)</span>
                </div>
              </template>

              <vue-autosuggest
                id="phone-contact"
                v-remove-non-numeric-chars.allNumber.noSpace
                :value="customerToSelect.phone"
                :suggestions="customerSuggestion"
                :input-props="{
                  id:'phone-contact',
                  placeholder: $t('flight.placeholderInput'),
                  state: `${getValidationState(validationContext) === false ? false : null}`,
                  maxlength:'30',
                  class:`form-control ${getValidationState(validationContext) === false ? 'is-invalid' : ''}`,
                  disabled: !!customerToSelect.paxId,
                }"
                :section-configs="sectionConfigs"
                :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion,'phoneNumber')"
                @input="val => inputHandle('phone', val.trim())"
              >
                <template slot-scope="{suggestion}">
                  <div class="cursor-pointer">
                    <b-row>
                      <b-col cols="12">
                        <HStack>
                          <b>{{ suggestion.item.lastName }} {{ suggestion.item.firstName }}</b>
                          <span v-if="suggestion.item?.nickName">({{ suggestion.item.nickName }})</span>
                        </HStack>
                      </b-col>

                      <b-col
                        cols="12"
                        class="text-nowrap"
                      >
                        {{ suggestion.item.phoneNumber }}
                      </b-col>
                      <b-col
                        cols="12"
                        class="text-nowrap"
                      >
                        {{ suggestion.item.emailAddress }}
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </vue-autosuggest>
              <b-tooltip
                v-if="validationContext.errors[0]"
                target="phone-contact"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
                :title="validationContext.errors[0]"
              >
                <span class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </span>
              </b-tooltip>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Email"
            :rules="`${isReplaceEmailTemp ? 'required|' : ''}email`"
          >
            <b-form-group label-for="email-contact">
              <template #label>
                <div class="">
                  {{ $t('flight.email') }}
                  <span
                    v-if="isReplaceEmailTemp"
                    class="text-danger"
                  >(*)</span>
                  <span v-if="isReplaceEmailTemp && !(isRoleF3 && agencyData?.sendMailAirline)">
                    <feather-icon
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.v-info
                      :title="$t('flight.infoEmailReplace')"
                      icon="AlertCircleIcon"
                      size="16"
                      class="text-info"
                      style="padding-bottom: 2px;"
                    />
                  </span>
                </div>
              </template>
              <vue-autosuggest
                id="email-contact"
                :value="customerToSelect.email"
                :suggestions="customerSuggestion"
                :input-props="{
                  id:'email-contact',
                  placeholder: $t('flight.placeholderInput'),
                  state: `${getValidationState(validationContext) === false ? false : null}`,
                  class:`form-control ${getValidationState(validationContext) === false ? 'is-invalid' : ''}`,
                  disabled: !!customerToSelect.paxId,
                }"
                :section-configs="sectionConfigs"
                :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion,'emailAddress')"
                @input="val => inputHandle('email', val)"
              >
                <template slot-scope="{suggestion}">
                  <div class="cursor-pointer">
                    <b-row>
                      <b-col cols="12">
                        <HStack>
                          <b>{{ suggestion.item.lastName }} {{ suggestion.item.firstName }}</b>
                          <span v-if="suggestion.item?.nickName">({{ suggestion.item.nickName }})</span>
                        </HStack>
                      </b-col>

                      <b-col
                        cols="12"
                        class="text-nowrap"
                      >
                        {{ suggestion.item.phoneNumber }}
                      </b-col>
                      <b-col
                        cols="12"
                        class="text-nowrap"
                      >
                        {{ suggestion.item.emailAddress }}
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </vue-autosuggest>
              <b-tooltip
                v-if="validationContext.errors[0]"
                target="email-contact"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
                :title="validationContext.errors[0]"
              >
                <span class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </span>
              </b-tooltip>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="(!isRoleF3 || (isRoleF3 && agencyData?.sendPhoneAirline)) && !isAutoCheckboxInContactsInfo"
          cols="6"
        >
          <b-form-checkbox
            v-model="isReplacePhoneTemp"
            class="mb-0"
            @input="inputCheckboxPhoneHandle"
          >
            <span>{{ $t('flight.checkboxReplacePhoneAgencyData') }}</span>
          </b-form-checkbox>
          <small class="text-danger">{{ $t('flight.detailReplacePhoneAgencyData') }}</small>
        </b-col>

        <b-col
          v-if="(!isRoleF3 || (isRoleF3 && agencyData?.sendMailAirline)) && !isAutoCheckboxInContactsInfo"
          cols="6"
        >
          <b-form-checkbox
            v-model="isReplaceEmailTemp"
            class="mb-0"
            @input="inputCheckboxEmailHandle"
          >
            <span>{{ $t('flight.checkboxReplaceEmailAgencyData') }}</span>
          </b-form-checkbox>
          <small class="text-danger">{{ $t('flight.detailReplaceEmailAgencyData') }}</small>
        </b-col>
      </b-row>
    </BCollapse>
  </BCard>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BButton, VBTooltip, BTooltip,
  BCollapse,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { VueAutosuggest } from 'vue-autosuggest'
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api'
import debounce from 'lodash/debounce'

import apiCustomer from '@/api/customer'
import env from '@/libs/env'
import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'
import { formatPhoneNumber } from '@core/comp-functions/forms/formatter-input'

import {
  required, min, max, email, phoneNumber,
} from '@validations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BTooltip,
    VueAutosuggest,
    BCollapse,

    // Form Validation
    ValidationProvider,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    customerData: {
      type: Object,
      default: () => {},
    },
    checkboxCustomerDataReplacePhoneAgencyData: {
      type: Boolean,
      default: false,
    },
    checkboxCustomerDataReplaceEmailAgencyData: {
      type: Boolean,
      default: false,
    },
    isRoleF3: {
      type: Boolean,
      default: true,
    },
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const blankCustomerToSelect = {
      code: 'string',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      homePhone: '',
      address: '',
      country: 'VN',
      language: '',
      paxCode: 'string',
      paxId: null,
    }
    const customerToSelect = ref({ ...blankCustomerToSelect })

    const { refFormObserver, getValidationState } = formValidation()

    const customerSuggestion = ref([])
    const agencyData = computed(() => store.getters['userStore/getAgencyData'])

    const debounceInput = debounce(async input => {
      const res = await apiCustomer.fetchCustomers({ searchText: input })
      if (res) {
        customerSuggestion.value = [{ data: res.data.items }]
      }
    }, 300)
    const inputHandle = (field, input) => {
      const inp = (field === 'phone' ? formatPhoneNumber(input) : input).trim()
      customerToSelect.value[field] = inp
      debounceInput(inp)
    }

    const sectionConfigs = ref({
      default: {
        onSelected: selected => {
          customerToSelect.value = {
            ...customerToSelect.value,
            paxId: selected.item.id,
            firstName: selected.item.firstName,
            lastName: selected.item.lastName,
            phone: (selected.item.phoneNumber ?? '').trim(),
            email: (selected.item.emailAddress ?? '').trim(),
            nickName: selected.item.nickName,
          }
        },
      },
    })

    function removeCustomerSelected() {
      customerToSelect.value = { ...blankCustomerToSelect }
    }
    watch(customerToSelect, () => {
      emit('update:customerData', customerToSelect.value)
    }, { deep: true })

    const customerData = toRefs(props).customerData

    watch(customerData, (newCustomer, oldCustomer) => {
      if (newCustomer?.id !== oldCustomer?.id && newCustomer) {
        customerToSelect.value = {
          id: newCustomer.id,
          phone: (newCustomer.phoneNumber ?? '').trim(),
          firstName: newCustomer.firstName,
          lastName: newCustomer.lastName,
          email: (newCustomer.emailAddress ?? '').trim(),
          code: newCustomer.code,
          homePhone: '',
          address: newCustomer.addressStreet ?? '',
          country: '',
          language: '',
          paxCode: '',
          paxId: newCustomer.id,
        }
      }
    })

    function getSuggestionValue(suggestion, val) {
      return suggestion.item[val]
    }

    const isReplacePhoneTemp = ref(props.checkboxCustomerDataReplacePhoneAgencyData)
    const isReplaceEmailTemp = ref(props.checkboxCustomerDataReplaceEmailAgencyData)
    function inputCheckboxPhoneHandle(val) {
      if (val) {
        emit('resetAgencyData')
      }
      emit('update:checkboxCustomerDataReplacePhoneAgencyData', isReplacePhoneTemp.value)
    }

    function inputCheckboxEmailHandle(val) {
      if (val) {
        emit('resetAgencyData')
      }
      emit('update:checkboxCustomerDataReplaceEmailAgencyData', isReplaceEmailTemp.value)
    }

    return {
      refFormObserver,
      getValidationState,
      isReplacePhoneTemp,
      isReplaceEmailTemp,
      isAutoCheckboxInContactsInfo: env.isAutoCheckboxInContactsInfo,

      required,
      min,
      max,
      email,
      phoneNumber,
      inputHandle,
      inputCheckboxPhoneHandle,
      inputCheckboxEmailHandle,
      customerSuggestion,
      customerToSelect,
      sectionConfigs,
      getSuggestionValue,
      removeCustomerSelected,
      formatPhoneNumber,
      agencyData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
