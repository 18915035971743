import { computed } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

// import { env } from '@/libs/env'
import store from '@/store'

const VJ_CHINA_REQUIRED_PASSPORT_IATA_CODES = new Set([
  'DOY', 'URC', 'JUH', 'WEH', 'CGQ', 'PVG', 'KOW', 'LUM', 'DDG', 'CGD',
  'HLH', 'TCZ', 'SHP', 'HTN', 'BSD', 'HDG', 'ENH', 'HYN', 'MXZ', 'LHK',
  'THQ', 'NGQ', 'KRL', 'DLC', 'DQA', 'XIN', 'SXJ', 'TCG', 'XIC', 'JDZ',
  'JGN', 'CHG', 'LLF', 'WEF', 'CNI', 'AAT', 'SHE', 'AKA', 'CIH', 'HNY',
  'LDS', 'YIW', 'YIH', 'SYM', 'LIA', 'UYN', 'BPX', 'AVA', 'XIL', 'HEK',
  'DIG', 'WXN', 'LYG', 'KCA', 'SWA', 'DAX', 'HUZ', 'MIG', 'IQN', 'LZH',
  'BAV', 'SZV', 'HMI', 'GOQ', 'IQM', 'AOG', 'GYU', 'HFE', 'INC', 'YNT',
  'DNH', 'JIC', 'JIQ', 'MDG', 'AQG', 'GYS', 'JJN', 'HIA', 'ZHA', 'BFU',
  'TGO', 'RUG', 'JIL', 'XEN', 'AEB', 'TYN', 'JHG', 'AKU', 'CIF', 'FUG',
  'YIN', 'ZAT', 'ZUH', 'LXA', 'SHS', 'YNJ', 'AYN', 'BHY', 'XUZ', 'WHU',
  'NAO', 'ZYI', 'TNH', 'NGB', 'OHE', 'ACX', 'JZH', 'FUO', 'JIU', 'JMU',
  'LCX', 'YNZ', 'HLD', 'LZY', 'BPL', 'LNJ', 'WUS', 'JUZ', 'JNG', 'HZG',
  'TXN', 'LZO', 'WUZ', 'YBP', 'XNN', 'NAY', 'XFN', 'TEN', 'HET', 'HSN',
  'LYI', 'JNZ', 'DAT', 'KWL', 'YUS', 'HJJ', 'ENY', 'KWE', 'ZHY', 'KRY',
  'HAK', 'JGS', 'NDG', 'NNY', 'FOC', 'CSX', 'KHN', 'SYX', 'LHW', 'YCU',
  'KHG', 'WNZ', 'CGO', 'DYG', 'CKG', 'TSN', 'SHA', 'LJG', 'PKX', 'HZA',
  'NKG', 'WHA', 'CTU', 'WUX', 'TAO', 'CAN', 'XMN', 'PEK', 'LYA', 'HRB',
  'DSN', 'DLU', 'XIY', 'TNA', 'SJW', 'NNG', 'TFU', 'HGH', 'CZX', 'SIA',
  'NTG', 'WUH', 'SZX', 'KMG',
])

// TPE => country TW
// "MFK","PIF","MZG","HCN","GNI","WOT","TTT","HUN","TSA","LZN","CYI","KNH","KYD","TNN","TPE","TXG","RMQ","KHH",

const SOURCE_CAN_BOOK_WITH_ANCILLARY = ['VN1A', 'VJ', 'VU', 'AK', 'TH', '1A', 'QH', 'TR', 'JQ']
const SOURCE_CAN_BOOK_WITH_SEAT = ['VN1A', 'VJ', 'VU', 'AK', 'QH', 'TR', 'JQ']

const sourcesRequireVerifyPrice = ['VN1A', '1A', '1B', 'TR', 'JQ']

const excludedBundleSsrJQ = [
  'STPL', 'BG20', 'FSX1', 'ML01',
  'LOYT', 'FLXN', 'FSX2', 'DCHG',
  'SDCH', 'CVHR', 'QBRB', 'FPLS',
  'FSX3', 'OCHG', 'CHHR', 'BMXN',
  'CB14', 'BG30', 'MLBX', 'CCSH',
  'OOCP', 'LNGE', 'IFOO',
]

const bundlesJQ = [
  {
    name: 'Starter Plus',
    bundleCode: 'P200',
    includeCheckedBaggage: true,
    bundles: [
      {
        code: 'STPL',
        value: '7kg Carry On',
        type: 'SSR_OTHERS',
      },
      {
        code: 'BG20',
        value: '20kg Checked Baggage',
        type: 'SSR_BAGGAGE',
      },
      {
        code: 'FSX1',
        value: 'Standard Seat',
        type: 'SSR_OTHERS',
      },
      {
        code: 'ML01',
        value: 'Meal',
        type: 'SSR_OTHERS',
      },
    ],
  },
  {
    bundleCode: 'P201',
    name: 'Starter Plus',
    includeCheckedBaggage: true,
    bundles: [
      {
        code: 'STPL',
        value: '7kg Carry On',
        type: 'SSR_OTHERS',
      },
      {
        code: 'BG20',
        value: '20kg Checked Baggage',
        type: 'SSR_BAGGAGE',
      },
      {
        code: 'FSX1',
        value: 'Standard Seat',
        type: 'SSR_OTHERS',
      },
      {
        code: 'STPL',
        value: 'Meal',
        type: 'SSR_OTHERS',
      },
      {
        code: 'LOYT',
        value: 'Loyalty Points',
        type: 'SSR_OTHERS',
      },
    ],
  },
  {
    bundleCode: 'F200',
    name: 'FLEX2',
    includeCheckedBaggage: false,
    bundles: [
      {
        code: 'FLXN',
        value: '7kg Carry On',
        type: 'SSR_OTHERS',
      },
      {
        code: 'FSX2',
        value: 'Standard + Upfront Seat',
        type: 'SSR_OTHERS',
      },
      {
        code: 'DCHG',
        value: 'Date/Time Change',
        type: 'SSR_OTHERS',
      },
    ],
  },
  {
    bundleCode: 'F201',
    name: 'FLEX2',
    includeCheckedBaggage: false,
    bundles: [
      {
        code: 'FLXN',
        value: '7kg Carry On',
        type: 'SSR_OTHERS',
      },
      {
        code: 'FSX2',
        value: 'Standard + Upfront Seat',
        type: 'SSR_OTHERS',
      },
      {
        code: 'DCHG',
        value: 'Date/Time Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'SDCH',
        value: 'Same Day Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'CVHR',
        value: 'Cancel Credit Voucher',
        type: 'SSR_OTHERS',
      },
      {
        code: 'LOYT',
        value: 'Loyalty Points',
        type: 'SSR_OTHERS',
      },
    ],
  },
  {
    bundleCode: 'F202',
    name: 'FLEX2',
    includeCheckedBaggage: false,
    bundles: [
      {
        code: 'FLXN',
        value: '7kg Carry On',
        type: 'SSR_OTHERS',
      },
      {
        code: 'FSX2',
        value: 'Standard + Upfront Seat',
        type: 'SSR_OTHERS',
      },
      {
        code: 'DCHG',
        value: 'Date/Time Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'SDCH',
        value: 'Same Day Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'CVHR',
        value: 'Cancel Credit Voucher',
        type: 'SSR_OTHERS',
      },
      {
        code: 'LOYT',
        value: 'Loyalty Points',
        type: 'SSR_OTHERS',
      },
      {
        code: 'ML01',
        value: 'Meal',
        type: 'SSR_OTHERS',
      },
      {
        code: 'QBRB',
        value: 'Qantas Business Rewards',
        type: 'SSR_OTHERS',
      },
    ],
  },
  {
    bundleCode: 'F203',
    name: 'FLEX2',
    includeCheckedBaggage: false,
    bundles: [
      {
        code: 'FLXN',
        value: '7kg Carry On',
        type: 'SSR_OTHERS',
      },
      {
        code: 'FSX2',
        value: 'Standard + Upfront Seat',
        type: 'SSR_OTHERS',
      },
      {
        code: 'DCHG',
        value: 'Date/Time Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'SDCH',
        value: 'Same Day Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'CVHR',
        value: 'Cancel Credit Voucher',
        type: 'SSR_OTHERS',
      },
      {
        code: 'LOYT',
        value: 'Loyalty Points',
        type: 'SSR_OTHERS',
      },
      {
        code: 'ML01',
        value: 'Meal',
        type: 'SSR_OTHERS',
      },
    ],
  },
  {
    bundleCode: 'M200',
    name: 'FLEX PLUS',
    includeCheckedBaggage: true,
    bundles: [
      {
        code: 'FPLS',
        value: '7kg Carry On',
        type: 'SSR_OTHERS',
      },
      {
        code: 'BG20',
        value: '20kg Checked Baggage',
        type: 'SSR_BAGGAGE',
      },
      {
        code: 'FSX3',
        value: 'Standard +Upfront + ExtraLeg Room Seat',
        type: 'SSR_OTHERS',
      },
      {
        code: 'ML01',
        value: 'Meal',
        type: 'SSR_OTHERS',
      },
      {
        code: 'DCHG',
        value: 'Date/Time Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'SDCH',
        value: 'Same Day Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'OCHG',
        value: 'Origin/Destination Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'CHHR',
        value: 'Cancel Credit Voucher',
        type: 'SSR_OTHERS',
      },
      {
        code: 'LOYT',
        value: 'Loyalty Points',
        type: 'SSR_OTHERS',
      },
    ],
  },
  {
    bundleCode: 'M201',
    name: 'FLEX PLUS',
    includeCheckedBaggage: true,
    bundles: [
      {
        code: 'FPLS',
        value: '7kg Carry On',
        type: 'SSR_OTHERS',
      },
      {
        code: 'BG20',
        value: '20kg Checked Baggage',
        type: 'SSR_BAGGAGE',
      },
      {
        code: 'FSX3',
        value: 'Standard +Upfront + ExtraLeg Room Seat',
        type: 'SSR_OTHERS',
      },
      {
        code: 'ML01',
        value: 'Meal',
        type: 'SSR_OTHERS',
      },
      {
        code: 'DCHG',
        value: 'Date/Time Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'SDCH',
        value: 'Same Day Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'OCHG',
        value: 'Origin/Destination Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'CHHR',
        value: 'Cancel Credit Voucher',
        type: 'SSR_OTHERS',
      },
      {
        code: 'LOYT',
        value: 'Loyalty Points',
        type: 'SSR_OTHERS',
      },
      {
        code: 'QBRB',
        value: 'Qantas Loyalty Points',
        type: 'SSR_OTHERS',
      },
    ],
  },
  {
    bundleCode: 'M202',
    name: 'FLEX PLUS',
    includeCheckedBaggage: true,
    bundles: [
      {
        code: 'FPLS',
        value: '7kg Carry On',
        type: 'SSR_OTHERS',
      },
      {
        code: 'BG20',
        value: '20kg Checked Baggage',
        type: 'SSR_BAGGAGE',
      },
      {
        code: 'FSX3',
        value: 'Standard +Upfront + ExtraLeg Room Seat',
        type: 'SSR_OTHERS',
      },
      {
        code: 'ML01',
        value: 'Meal',
        type: 'SSR_OTHERS',
      },
      {
        code: 'DCHG',
        value: 'Date/Time Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'SDCH',
        value: 'Same Day Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'OCHG',
        value: 'Origin/Destination Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'CHHR',
        value: 'Cancel Credit Voucher',
        type: 'SSR_OTHERS',
      },
      {
        code: 'LOYT',
        value: 'Loyalty Points',
        type: 'SSR_OTHERS',
      },
      {
        code: 'QBRB',
        value: 'Qantas Loyalty Points',
        type: 'SSR_OTHERS',
      },
    ],
  },
  {
    bundleCode: 'X201',
    name: 'BUSINESS MAX 2',
    includeCheckedBaggage: true,
    bundles: [
      {
        code: 'BMXN',
        value: 'Include',
        type: 'SSR_OTHERS',
      },
      {
        code: 'CB14',
        value: '14kg Carry On',
        type: 'SSR_OTHERS',
      },
      {
        code: 'BG30',
        value: '30kg Checked Baggage',
        type: 'SSR_BAGGAGE',
      },
      {
        code: 'MLBX',
        value: 'Member Business',
        type: 'SSR_OTHERS',
      },
      {
        code: 'DCHG',
        value: 'Date/Time Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'OCHG',
        value: 'Origin/Destination Change',
        type: 'SSR_OTHERS',
      },
      {
        code: 'CVHR',
        value: 'Credit Voucher',
        type: 'SSR_OTHERS',
      },
      {
        code: 'CCSH',
        value: 'Refundable',
        type: 'SSR_OTHERS',
      },
      {
        code: 'OOCP',
        value: 'Comfort Pack',
        type: 'SSR_OTHERS',
      },
      {
        code: 'LNGE',
        value: 'Lounge',
        type: 'SSR_OTHERS',
      },
      {
        code: 'LOYT',
        value: 'Loyalty Points',
        type: 'SSR_OTHERS',
      },
      {
        code: 'IFOO',
        value: 'Inflight Entertainment',
        type: 'SSR_OTHERS',
      },
      {
        code: 'QBRB',
        value: 'Qantas Business Reward',
        type: 'SSR_OTHERS',
      },
    ],
  },
]

export default function useCreateBookingHandle() {
  const CREATE_BOOKING_STORE_MODULE_NAME = 'app-create-booking'
  // const { isDevelopment } = env

  // eslint-disable-next-line arrow-body-style
  const sourcesCanBuyAncillary = computed(() => { // HÀNH LÝ, DV KHÁC
    // if disable env
    return SOURCE_CAN_BOOK_WITH_ANCILLARY
  })

  // eslint-disable-next-line arrow-body-style
  const sourcesCanBuySeat = computed(() => { // CHỖ NGỒI
    // if disable env
    return SOURCE_CAN_BOOK_WITH_SEAT
  })

  function canBuyAncillary(source, clientId, contentSource) {
    const validSource = sourcesCanBuyAncillary.value.includes(source)
    const validClientId = ['VN1A'].includes(source) ? !['INTERNAL'].includes(clientId) : true
    const validContentSource = ['1A'].includes(source) ? ['GDS'].includes(contentSource) : true
    return validSource && validClientId && validContentSource
  }

  function canBuySeat(source, clientId, contentSource) {
    const validSource = sourcesCanBuySeat.value.includes(source)
    const validClientId = ['VN1A'].includes(source) ? !['INTERNAL'].includes(clientId) : true
    const validContentSource = true
    return validSource && validClientId && validContentSource
  }

  const isHideFunction = computed(() => store.getters['app-flight-v2/getIsHideFunction'])

  function toggleHideFunction() {
    store.dispatch('app-flight-v2/toggleHideFunction')
  }

  const convertFirstName = firstName => {
    const arrName = firstName.split(' ')
    return arrName.map((item, index) => (index !== arrName.length - 1 ? item[0] : item)).join(' ')
  }

  const calculateTotalLength = (human, isConvertName = false, sources) => {
    const firstNameLength = (human.paxType === 'INFANT' && isConvertName) ? (convertFirstName(human.firstName)?.length || 0) : (human.firstName?.length || 0)
    return (human.title?.length || 0)
        + firstNameLength
        + (human.lastName?.length || 0)
        + (sources.some(s => ['1A'].includes(s)) && !['INFANT'].includes(human.paxType) ? 0 : (human.birthday?.length || 0))
  }

  const createData = (type, pax, totalLength, isOverLimit, canModify, infantPaxId = null) => ({
    type,
    pax: cloneDeep(pax),
    strNameInfant: type === 'double' ? convertFirstName(pax[0].firstName) : null,
    isOverLimit,
    canModify,
    totalLength,
    infantPaxId,
  })

  const pooledPaxCheckMaxLength = (paxs, sources) => {
    const result = []

    paxs.forEach(pax => {
      if (pax.paxType === 'INFANT' && pax.parentPaxId) {
        const adult = paxs.find(f => f.paxId === pax.parentPaxId)
        const infantArr = [pax, adult]
        const totalLength = infantArr.reduce((total, human) => total + calculateTotalLength(human, false, sources), 0)
        const totalLengthModifyINF = infantArr.reduce((total, human) => total + calculateTotalLength(human, true, sources), 0)
        const isOverLimit = sources.some(s => ['1A'].includes(s)) ? (totalLength > 57) : (totalLength > 47)
        const canModify = sources.some(s => ['1A'].includes(s))
          ? false // (isOverLimit && totalLengthModifyINF < 57) -> 1A no-edit name INF
          : (isOverLimit && totalLengthModifyINF < 47)
        const data = createData('double', infantArr, totalLength, isOverLimit, canModify, pax.paxId)
        result.push(data)
      } else {
        const totalLength = calculateTotalLength(pax, false, sources)
        const isOverLimit = sources.some(s => ['1A'].includes(s)) ? (totalLength > 57) : (totalLength > 54)
        const data = createData('one', pax, totalLength, isOverLimit, false, null)
        result.push(data)
      }
    })

    return result
  }

  const updatePassengerCountToLocalStorage = paxLists => {
    const storageData = JSON.parse(localStorage.getItem('searchFlight')) || {};
    ['ADULT', 'CHILD', 'INFANT'].forEach(paxType => {
      storageData[paxType.toLowerCase()] = paxLists.filter(p => p.paxType === paxType).length
    })
    localStorage.setItem('searchFlight', JSON.stringify(storageData))
  }

  return {
    sourcesCanBuyAncillary,
    sourcesCanBuySeat,
    sourcesRequireVerifyPrice,
    convertFirstName,
    pooledPaxCheckMaxLength,
    updatePassengerCountToLocalStorage,

    isHideFunction,
    toggleHideFunction,

    canBuyAncillary,
    canBuySeat,

    excludedBundleSsrJQ,
    bundlesJQ,

    CREATE_BOOKING_STORE_MODULE_NAME,
    VJ_CHINA_REQUIRED_PASSPORT_IATA_CODES,
  }
}
