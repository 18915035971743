var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "suggest-shop-modal",
      "shadow": "",
      "size": "lg",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "header-bg-variant": "light-info",
      "hide-footer": "",
      "title": _vm.$t('suggestShopVJ')
    }
  }, [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingShopVJ
    }
  }, [_c('b-table', {
    ref: "refResellTicket",
    staticClass: "position-relative bg-white",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "bordered": "",
      "hover": "",
      "no-border-collapse": "",
      "sticky-header": "",
      "responsive": "",
      "show-empty": "",
      "primary-key": "id",
      "table-class": "table-resell-ticket",
      "items": _vm.searchResellTickets,
      "fields": _vm.shopColumns,
      "empty-text": _vm.$t('noRecordFund')
    },
    scopedSlots: _vm._u([_vm._l(_vm.shopColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-dark text-nowrap text-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("resellTicket.columns.".concat(data.label))) + " ")])];
        }
      };
    }), _vm._l(_vm.shopColumns, function (column) {
      return {
        key: "cell(".concat(column.key, ")"),
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_c('div', {
            key: column.key,
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(item[column.key]) + " ")])];
        }
      };
    }), {
      key: "cell(stt)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(data.index + 1) + " ")])];
      }
    }, {
      key: "cell(price)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.price ? _c('div', [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.price)) + " ")]) : _c('div', {
          staticClass: "d-flex-center"
        }, [_c('div', {
          staticClass: "badge badge-info badge-glow p-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.agreement')) + " ")])])];
      }
    }, {
      key: "cell(phone)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_c('a', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-dark.window",
            value: "".concat(_vm.$t('resellTicket.btn.call')),
            expression: "`${$t('resellTicket.btn.call')}`",
            modifiers: {
              "hover": true,
              "v-dark": true,
              "window": true
            }
          }],
          staticClass: "hover-underline",
          attrs: {
            "href": 'tel:' + item.phone
          }
        }, [_vm._v(" " + _vm._s(item.phone) + " ")])])];
      }
    }, {
      key: "cell(expiredDate)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.isDateGreaterThanOrEqualToToday(item.expiredDate) ? _vm.convertISODateTime(item.expiredDate).date : _vm.$t('resellTicket.expired')) + " ")])];
      }
    }, {
      key: "cell(note)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "min-width-200 text-break text-justify"
        }, [_vm._v(" " + _vm._s(item.note) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex gap-1 align-items-center"
        }, [!item.reported && _vm.isRoleF2 ? _c('b-button', {
          staticClass: "p-50 rounded text-nowrap",
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              return _vm.createReport(item.id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.btn.report')) + " ")]) : _vm._e()], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1), _c('CreateReportResellTicketModal', {
    attrs: {
      "id": _vm.resellTicketId
    },
    on: {
      "refresh": _vm.searchResellTicket
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }