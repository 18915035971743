<template>
  <validation-observer
    #default="{invalid, pristine }"
    ref="refFormObserver"
  >
    <b-modal
      id="modal-register-member-ship-card"
      :title="$t('flight.modalMemberShipCard')"
      title-class="font-weight-bolder text-airline font-medium-3"
      centered
      size="lg"
      no-close-on-backdrop
      @show="handleOpenModal"
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient border-0"
          :disabled="invalid || pristine"
          pill
          @click="handleCreateMembershipCard"
        >
          <span class="align-middle mb-25">{{ $t('reservation.confirm') }}</span>
        </b-button>
      </template>

      <b-form>
        <b-card
          header-bg-variant="light-info"
          header-class="py-1"
          body-class="pb-50"
        >
          <template #header>
            <span class="text-airline font-weight-bolder">
              {{ $t('flight.passengerInfo') }}
            </span>
            <!-- <small class="text-warning">({{ $t(`reservation.${passengerData.paxType}`) }})</small> -->
          </template>

          <b-row class="mt-50">
            <!-- ANCHOR - Title -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('flight.Honorific')"
                rules="required"
              >
                <b-form-group label-for="honorific">
                  <template #label>
                    <div class="text-nowrap">
                      {{ $t('flight.Honorific') }}
                      <span class="text-danger">(*)</span>
                    </div>
                  </template>

                  <v-select
                    id="honorific"
                    v-model="membershipCardDataToAdd.title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="titleOpts"
                    label="text"
                    class="select-size-md"
                    :clearable="false"
                    :placeholder="`${$t('flight.placeholderSelect')}`"
                    :reduce="val => val.value"
                  >
                    <template #option="data">
                      <span class="font-small-3">
                        {{ $t(data.text) }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span class="font-small-3">
                        {{ $t(data.text) }}
                      </span>
                    </template>
                  </v-select>

                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    target="honorific"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR Date Of Birth -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="Birthday"
                :rules="membershipCardDataToAdd.title ? getValidateBirthday(membershipCardDataToAdd.title) : getValidateBirthday('MR')"
                vid="dob"
              >
                <b-form-group
                  id="dob-target"
                  class="mb-0"
                >
                  <template #label>
                    <div class="font-small-4">
                      {{ $t('flight.dob') }}
                      <span class="text-danger">(*)</span>
                    </div>
                  </template>

                  <flat-pickr
                    v-model="membershipCardDataToAdd.birthDay"
                    class="d-none"
                  />
                  <div
                    class="form-control p-0"
                    :class="validationContext.errors[0] ? 'is-invalid' : ''"
                  >
                    <flat-pickr
                      id="dob-"
                      v-model="membershipCardDataToAdd.birthDay"
                      :name="$t('flight.dob')"
                      class="form-control px-50 py-0"
                      :config="{
                        ...configFlatPickr,
                        altInputClass: 'form-control input px-50 py-0 border-transparent',
                      }"
                      :style="{height: '40px'}"
                      :placeholder="$t('flight.placeholderInput')"
                    />
                  </div>

                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    target="dob-target"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR Last Name -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('flight.lastName')"
                rules="required|max:30"
              >
                <b-form-group label-for="last-name">
                  <template #label>
                    <div>
                      {{ $t('flight.lastName') }}
                      <span class="text-danger">(*)</span>
                    </div>
                  </template>
                  <b-form-input
                    id="last-name"
                    v-model="membershipCardDataToAdd.lastName"
                    :state="getValidationState(validationContext) === false ? false : null"
                    maxlength="30"
                    lazy-formatter
                    :formatter="formatterInput"
                    :placeholder="$t('flight.placeholderInput')"
                  />
                  <!-- :placeholder="$t('placeholderLastName')" -->

                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    target="last-name"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR First Name -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('flight.firstName')"
                rules="required|max:30"
              >
                <b-form-group label-for="first-name">
                  <template #label>
                    <div>
                      {{ $t('flight.firstName') }}
                      <span class="text-danger">(*)</span>
                    </div>
                  </template>
                  <b-form-input
                    id="first-name"
                    v-model="membershipCardDataToAdd.firstName"
                    :state="getValidationState(validationContext) === false ? false : null"
                    maxlength="30"
                    lazy-formatter
                    :formatter="formatterInput"
                    :placeholder="$t('flight.placeholderInput')"
                  />
                  <!-- :placeholder="$t('placeholderFirstName')" -->

                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    target="first-name"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR Email -->
            <b-col
              cols="12"
              sm="6"
            >
              <!-- rules="required|email|isUnique:employee,email" -->
              <validation-provider
                #default="validationContext"
                :name="$t('flight.email')"
                rules="required|email"
              >
                <b-form-group label-for="email">
                  <template #label>
                    <div>
                      {{ $t('flight.email') }}
                      <span class="text-danger">(*)</span>
                    </div>
                  </template>
                  <b-form-input
                    id="email"
                    v-model="membershipCardDataToAdd.email"
                    :state="getValidationState(validationContext) === false ? false : null"
                    maxlength="50"
                    :debounce="300"
                    :formatter="removeAccentsFormatter"
                    :placeholder="$t('flight.placeholderInput')"
                  />

                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    target="email"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR Phone -->
            <b-col
              cols="12"
              sm="6"
            >
              <!-- rules="required|phoneNumber|isUnique:employee,phonenumber" -->
              <validation-provider
                #default="validationContext"
                :name="$t('flight.phone')"
                rules="required|phoneNumber"
              >
                <b-form-group label-for="phone">
                  <template #label>
                    <div>
                      {{ $t('flight.phone') }}
                      <span class="text-danger">(*)</span>
                    </div>
                  </template>
                  <div class="d-flex">
                    <b-form-input
                      id="phone"
                      v-model="membershipCardDataToAdd.phoneNumber"
                      v-remove-non-numeric-chars.allNumber
                      :state=" getValidationState(validationContext) === false ? false : null "
                      :debounce="300"
                      :formatter="formatterInput"
                      :placeholder="$t('flight.placeholderInput')"
                    />
                  </div>

                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    target="phone"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR Nationality -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('flight.nationality')"
                rules="required"
              >
                <b-form-group label-for="nationality">
                  <template #label>
                    <div>
                      {{ $t('flight.nationality') }}
                      <span class="text-danger">(*)</span>
                    </div>
                  </template>
                  <v-select
                    id="nationality"
                    v-model="membershipCardDataToAdd.nationality"
                    :options="nationalityOptions"
                    :clearable="false"
                    label="text"
                    :disabled="true"
                    :placeholder="`${$t('flight.placeholderSelect')}`"
                  >
                    <template #option="data">
                      <span>
                        {{ data.text }}
                      </span>
                    </template>
                    <template #selected-option="data">
                      <span>
                        {{ data.text }}
                      </span>
                    </template>
                  </v-select>
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
        <!-- ANCHOR Airline Select -->
        <b-card
          v-if="!isResult"
          header-bg-variant="light-info"
          header-class="py-1"
          body-class="pb-50"
        >
          <template #header>
            <span class="text-airline font-weight-bolder">
              {{ $t('flight.selectAirlineCreateMembershipCard') }}
            </span>
          </template>

          <validation-provider
            #default="{ errors }"
            tag="div"
            vid="vidTicketType"
            rules="required"
            :name="$t('invoice.ticketType')"
          >
            <div class="custom-label-checkbox mt-1">
              <b-form-group
                class="mb-0"
                label-class="font-weight-bold font-medium-2"
                :label="`${$t('flight.labelSelectAirlines')}:`"
                :state="errors[0] ? false : null"
              >
                <template #label>
                  <b-form-checkbox
                    v-model="allSelected"
                    aria-describedby="flights"
                    aria-controls="flights"
                    class="custom-checkbox-label mb-1"
                    @change="toggleAll"
                  >
                    <div class="text-body-2">
                      {{ allSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
                    </div>
                  </b-form-checkbox>
                </template>

                <template #default="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="airlines"
                    :aria-describedby="ariaDescribedby"
                    stacked
                    name="flights"
                  >
                    <div
                      v-for="(data, index) in airlineOptions"
                      :key="index"
                      class="d-flex"
                    >
                      <b-form-checkbox
                        :value="data"
                        class="custom-checkbox-label mb-50"
                      >
                        <span
                          class="text-airline text-nowrap mb-50"
                          :class="isMobileView ? 'font-medium-1' : 'font-medium-3'"
                        >
                          {{ $t(data.label) }} ({{ data.code === 'VN' ? `${$t('reservation.BSV')}` : `${$t('reservation.BBClub')}` }})
                        </span>
                      </b-form-checkbox>
                    </div>
                  </b-form-checkbox-group>
                </template>
              </b-form-group>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </div>
          </validation-provider>
        </b-card>

        <!-- ANCHOR RESULT -->
        <b-card
          v-else
          header-bg-variant="light-info"
          header-class="py-1"
          body-class="pb-50"
        >
          <template #header>
            <span class="text-airline font-weight-bolder">
              {{ $t('flight.creatingMembershipCard') }}
            </span>
          </template>
          <div
            v-for="(data, index) in airlines"
            :key="index"
            class="d-flex-between"
          >
            <div
              class="text-airline text-nowrap my-50"
              :class="isMobileView ? 'font-medium-1' : 'font-medium-3'"
            >
              {{ $t(data.label) }} ({{ data.code === 'VN' ? `${$t('reservation.BSV')}` : `${$t('reservation.BBClub')}` }})
            </div>
            <div
              v-if="resultData.find(resItem => resItem.airline.code === data.code)?.cardNumber"
              class="d-flex-center gap-2"
            >
              Số thẻ:
              <span class="text-info font-weight-bolder ml-25">
                {{ resultData.find(resItem => resItem.airline.code === data.code).cardNumber }}
              </span>

              <BButton
                v-if="airlineLists.includes(data.code)"
                variant="outline-info"
                class="ml-25 p-25 rounded-pill"
                @click="applyMembershipCard"
              >
                Áp dụng
              </BButton>
            </div>

            <div
              v-else-if="resultData.find(resItem => resItem.airline.code === data.code)?.msgError"
              class="text-danger"
            >
              Lỗi: {{ resultData.find(resItem => resItem.airline.code === data.code).msgError }}
            </div>
            <div v-else>
              <b-spinner
                variant="primary"
                small
                tag="span"
              />
            </div>
          </div>
        </b-card>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BCard,
  BTooltip,
  BFormCheckbox,
  BButton,
  BFormCheckboxGroup,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref, watch, computed, toRefs,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import { titleOptions } from '@/constants/reservation'
import { apiBooking } from '@/api/'
import VueI18n from '@/libs/i18n'
import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'
import { removeAccents, removeAccentsFormatter } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'
import {
  required, min, email, isUnique, userName,
} from '@validations'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckboxGroup,
    BFormCheckbox,
    BTooltip,
    BSpinner,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    memberFlyData: {
      type: Array,
      default: () => null,
    },
    dataSearchFlight: {
      type: Object,
      default: () => {},
    },
    airlineLists: {
      type: Array,
      default: () => [],
    },
    paxData: {
      type: Object,
      default: () => {},
    },
    place: {
      type: String,
      default: () => '',
    },
  },

  setup(props, { emit }) {
    const { toastError } = useToast()
    const blankMembershipCardData = {
      phoneCountryCode: '84',
      phoneNumber: '',
      email: '',
      title: '',
      firstName: '',
      lastName: '',
      birthDay: null,
      nationality: {
        text: 'Việt Nam',
        value1: 'VN',
        value2: 'VNM',
      },
      preferredLanguage: '',
      referralCode: '',
    }

    const airlineOptions = [
      {
        label: 'Bamboo Airways',
        key: 'BAMBOO',
        code: 'QH',
      },
      {
        label: 'Vietnam Airlines',
        key: 'VNA',
        code: 'VN',
      },
    ]

    const nationalityOptions = [{
      text: 'Việt Nam',
      value1: 'VN',
      value2: 'VNM',
    }]

    const isResult = ref(false)
    const resultData = ref([])
    const airlines = ref([])
    const isEmptyAirlines = computed(() => isEmpty(airlines.value))
    const membershipCardDataToAdd = ref(JSON.parse(JSON.stringify(blankMembershipCardData)))
    const resetMembershipCardData = () => {
      membershipCardDataToAdd.value = JSON.parse(JSON.stringify(blankMembershipCardData))
      airlines.value = []
    }
    const { refFormObserver, getValidationState } = formValidation(resetMembershipCardData)
    const titleOpts = [...titleOptions.ADULT, ...titleOptions.CHILD]

    const { airlineLists } = toRefs(props)
    const allSelected = ref(false)

    const getDefaultAirlineCheck = airArr => {
      if (!isEmpty(airArr)) {
        airArr.forEach(al => {
          airlineOptions.forEach(airObj => {
            if (airObj.code === al) {
              airlines.value.push(airObj)
            }
          })
        })
      }
    }

    const getTitle = pax => {
      if (!pax) return ''
      if (pax.title) return pax.title

      const splitTitle = pax.firstName.split(' ').slice(-1)
      const hasTitle = !isEmpty(splitTitle) ? titleOpts.some(t => t.value === splitTitle[0]) : false
      if (hasTitle) {
        return splitTitle[0]
      }
      return ''
    }
    const getFirstName = pax => {
      if (!pax) return ''
      if (pax.title) return pax.firstName

      const splitTitle = pax.firstName.split(' ').slice(-1)
      const hasTitle = !isEmpty(splitTitle) ? titleOpts.some(t => t.value === splitTitle[0]) : false
      if (hasTitle) {
        const data = pax.firstName.split(' ')
        const result = data.slice(0, data.length - 1).join(' ')
        return result
      }
      return ''
    }
    const setDataPax = (pax, place) => {
      membershipCardDataToAdd.value.title = ['modify'].includes(place) ? getTitle(pax) : pax?.title || ''
      membershipCardDataToAdd.value.firstName = ['modify'].includes(place) ? getFirstName(pax) : pax?.firstName || ''
      membershipCardDataToAdd.value.lastName = pax?.lastName || ''
      membershipCardDataToAdd.value.birthDay = pax?.birthday || null
    }

    function handleOpenModal() {
      resetMembershipCardData()
      allSelected.value = false
      const uniqueAirlineLists = [...new Set(airlineLists.value)]
      getDefaultAirlineCheck(uniqueAirlineLists)
      if (props.paxData) {
        setDataPax(props.paxData, props.place)
      }
      emit('update:memberFlyData', null)
      isResult.value = false
      resultData.value = []
    }

    const locale = computed(() => VueI18n.locale).value
    const configFlatPickr = ref(
      {
        dateFormat: 'Y-m-d',
        minDate: '1.1.1920',
        maxDate: 'today',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )

    const resolvePaxTypeByTitle = title => {
      if (titleOptions.CHILD.some(option => option.value === title)) {
        return 'CHILD'
      }

      return 'ADULT'
    }
    const getValidateBirthday = title => {
      const paxType = resolvePaxTypeByTitle(title)
      const { type, flights } = props.dataSearchFlight

      if (flights) {
        const departDate = flights[0].departDate
        const validationRules = {
          ADULT: `required|ageGroup:${type},${departDate},${paxType}`,
          CHILD: `required|ageGroup:${type},${departDate},${paxType}`,
        }
        return validationRules[paxType]
      }
      return ''
    }

    watch(airlines, value => {
      if (value) {
        if (value.length === 0) {
          allSelected.value = false
        } else if (value.length === airlineOptions.length) {
          allSelected.value = true
        } else {
          allSelected.value = false
        }
      }
    })

    function toggleAll(checked) {
      if (checked) {
        airlines.value = airlineOptions
      } else {
        airlines.value = []
      }
    }

    function createPayload(membershipCardData, airline) {
      const isBamboo = airline.key === 'BAMBOO'
      const nationality = isBamboo ? membershipCardData.nationality?.value2 : membershipCardData.nationality?.value1

      return {
        ...membershipCardData,
        nationality,
        phoneNumber: membershipCardData.phoneNumber,
        ...(isBamboo && {
          preferredLanguage: 'VIE',
          phoneNumber: membershipCardData.phoneNumber.replace(/^0+/, ''),
        }),
      }
    }

    const errorMessageList = computed(() => store.getters['globalConfig/getErrorMessage'])

    async function createMembershipCards(membershipCardData, airlines) {
      const result = await Promise.all(airlines.map(async airline => {
        try {
          const payload = createPayload(membershipCardData, airline)
          const res = await apiBooking.membershipCard(payload, { airlines: airline.key })

          const resData = {
            cardNumber: !isNaN(Number(res.cardNumber)) ? Number(res.cardNumber) : null,
            airline,
            msgError: res.cardNumber,
          }
          resultData.value.push(resData)
          return resData
        } catch (error) {
          console.error(`Error calling API for ${airline.label}:`, { error })
          const textShow = errorMessageList.value.find(i => i.key === error.response.data.message)
            || errorMessageList.value.find(err => JSON.stringify(error.response.data.message).includes(err.key))
          const result = {
            msgError: textShow?.value || error?.response?.data?.message || null,
            cardNumber: null,
            airline,
          }
          resultData.value.push(result)
          return result
        }
      }))

      return result
    }

    function handleError(error) {
      console.error({ error })
      toastError({
        title: 'Lỗi',
        content: 'Lỗi tạo mới thẻ thành viên, vui lòng kiểm tra lại',
      })
    }
    function applyMembershipCard() {
      emit('update:memberFlyData', resultData.value)
      this.$bvModal.hide('modal-register-member-ship-card')
    }

    async function handleCreateMembershipCard(event) {
      event.preventDefault()
      isResult.value = true

      try {
        const membershipCardData = membershipCardDataToAdd.value
        await createMembershipCards(membershipCardData, airlines.value)
      } catch (error) {
        handleError(error)
      }
    }

    function formatterInput(value) {
      const str = removeAccents(value)
      return str.toUpperCase().trim()
    }

    return {
      titleOpts,
      membershipCardDataToAdd,
      refFormObserver,
      getValidationState,
      formatterInput,
      handleCreateMembershipCard,
      handleOpenModal,
      getValidateBirthday,
      configFlatPickr,
      required,
      min,
      email,
      isUnique,
      userName,

      airlineOptions,
      nationalityOptions,

      allSelected,
      toggleAll,

      airlines,
      isEmptyAirlines,
      removeAccentsFormatter,
      isResult,
      resultData,
      applyMembershipCard,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.custom-label-checkbox::v-deep {
  .custom-control.custom-radio {
    margin-bottom: 10px !important;

    .custom-control-label {
      margin-top: -3px !important;
    }
  }
}

.custom-checkbox-label::v-deep {
  .custom-control-label {
    margin-top: 2px !important;
  }
}
</style>
